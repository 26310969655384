import {
	device
} from '@dg/common/lib/common'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import {
	useRouter
} from 'next/router'
import {
	useEffect
} from 'react'

const Index = () => {
	const router = useRouter()

	useEffect(() => {
		if (router.isReady === true) {
			router.replace((device().browser.mobile === true ? pathsData : pathsPcData).login)
		}
	}, [
		router
	])

	return (
		<section
			id="index"
		>
			<h2
				className="sr-only"
				style={
					{
						visibility: `hidden`
					}
				}
			>
				Redirect Page.
			</h2>
		</section>
	)
}

export default Index
